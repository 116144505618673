import { useContext } from 'react';
import { HighSecurityContext } from 'shared/contexts/HighSecurityContext';
import ConfirmLogin from './confirm-login/ConfirmLogin';

interface Props {
  children: React.ReactNode;
}

const HighSecurityTemplate = ({ children }: Props) => {
  const { isTokenTooOld } = useContext(HighSecurityContext);

  return isTokenTooOld ? <ConfirmLogin /> : <>{children}</>;
};

export default HighSecurityTemplate;
