import CircularProgress from '@mui/material/CircularProgress';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import './LoadingPage.scss';

const LoadingPage: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="loading">
      <div className="container">
        <div className="heading">
          <CircularProgress color="inherit" size={32} />
          <h1 className="h2 mb-0">{t('generic.loading')}...</h1>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
