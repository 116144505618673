import { FC } from 'react';

const Loader: FC = () => {
  return (
    <div className="things-to-do">
      <div className="container pt-4">
        <div className="loader-wrap">
          <div className="tag-list">
            <div className="tag-item loader-anim"></div>
            <div className="tag-item loader-anim"></div>
            <div className="tag-item loader-anim"></div>
            <div className="tag-item loader-anim"></div>
            <div className="tag-item loader-anim"></div>
            <div className="tag-item loader-anim"></div>
            <div className="tag-item loader-anim"></div>
          </div>

          <div className="filter-list"></div>

          <div className="list mt-2 p-4">
            <div className="list-item loader-anim"></div>
            <div className="list-item loader-anim"></div>
            <div className="list-item loader-anim"></div>
            <div className="list-item loader-anim"></div>
            <div className="list-item loader-anim"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
