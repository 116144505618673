import { FC } from 'react';
import './Flower.scss';

const Flower: FC = () => {
  return (
    <div className="flower">
      <div className="petal"></div>
      <div className="petal"></div>
      <div className="petal"></div>
      <div className="petal"></div>
      <div className="petal"></div>
      <div className="core"></div>
    </div>
  );
};

export default Flower;
