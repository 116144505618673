import { Button } from '@mui/material';
import logo from 'assets/images/logo-white.png';
import { useTranslation } from 'react-i18next';
import useLoginLogout from 'shared/hooks/useLoginLogout';
import './Login.scss';

const Login = () => {
  const { t } = useTranslation();
  const { login } = useLoginLogout();

  return (
    <div className="login">
      <div className="login-box">
        <div className="header py-3 px-4">
          <img src={logo} alt="Booking Platform" />
        </div>
        <div className="content pt-5 pb-4 px-4">
          <h1 className="h2 mb-1">{t('loginPage.title')}</h1>
          <p className="my-0">{t('loginPage.subTitle')}</p>
          <div className="sep mt-5 mb-4"></div>
          <Button variant="outlined" onClick={() => login(false)}>
            {t('loginPage.loginButton')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Login;
