import { FC, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RumContext } from 'shared/contexts/rum.context';

const PageMetrics: FC = () => {
  const awsRum = useContext(RumContext);
  const location = useLocation();

  useEffect(() => {
    awsRum && awsRum.recordPageView && awsRum.recordPageView(location.pathname);
  }, [location.pathname, awsRum]);

  return <></>;
};

export default PageMetrics;
