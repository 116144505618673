import { CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const Pending = () => {
  const { t } = useTranslation();

  return (
    <>
      <CircularProgress />
      <Typography>{t('paymentStatusPage.pending')}</Typography>
    </>
  );
};

export default Pending;
