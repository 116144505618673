import { PowerOff } from '@mui/icons-material';
import { Typography } from '@mui/material';

const Failed = () => (
  <>
    <PowerOff sx={{ width: '200px', height: '200px' }} color="error" />
    <Typography>Your payment could not be processed.</Typography>
  </>
);

export default Failed;
